import FormContext from '@root/core/src/contexts/form/form-context';
import FormDispatchContext from '@root/core/src/contexts/form/form-dispatch-context';
import PropTypes from '@root/vendor/prop-types';
import { useReducer } from '@root/vendor/react';

export const actionTypes = {
  SET_IS_LOADING: 'set_is_loading',
  UPDATE: 'update',
};

function formReducer(state, action) {
  switch (action.type) {
  case actionTypes.SET_IS_LOADING: {
    return {
      ...state,
      isLoading: action.isLoading,
      loadingText: action.loadingText,
    };
  }
  case actionTypes.UPDATE: {
    return {
      ...state,
      submitFunc: action.submitFunc,
      isFormValid: action.isFormValid,
    };
  }
  }
}

export default function FormProvider({ children, initialValues = initialFormProviderValues }) {
  const [values, dispatch] = useReducer(formReducer, initialValues);

  return (
    <FormContext.Provider
      value={values}
    >
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormContext.Provider>
  );
}

export const initialFormProviderValues = {
  isLoading: false,
  isFormValid: false,
  loadingText: '',
  submitFunc: () => {},
};

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.shape({
    isFormValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    submitFunc: PropTypes.func,
  }),
};
