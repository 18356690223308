import AppsflyerService from '@root/attribution/src/services/appsflyer-service';
import AttributionService from '@root/attribution/src/services/attribution-service';
import { useEffect, useRef } from '@root/vendor/react';

export default function useSaveAttribution({ search, pathname }) {
  const hasSavedAttribution = useRef(false);

  useEffect(() => {
    if (hasSavedAttribution.current) { return; }

    const href = `${document.location.protocol}//${document.location.hostname}${pathname}${search}`;
    AttributionService.storeAttributionReferralHref(href);
    hasSavedAttribution.current = true;

    const query = search;

    if (!query) { return; }
    AppsflyerService.storeAppsflyerReferralHref(href);
  }, [hasSavedAttribution, pathname, search]);

  return null;
}
