import AnalyticsService from '@root/core/src/services/analytics-service';
import AttributionQueryParamService from '@root/attribution/src/services/attribution-query-param-service';
import AttributionService from '@root/attribution/src/services/attribution-service';
import AuthService from '@root/core/src/services/auth-service';
import PolicyContext from '@root/auto-pricing/src/models/policy-context';
import PolicyContextProvider from '@root/auto-pricing/src/hooks/use-policy-context';
import PropTypes from '@root/vendor/prop-types';
import ProtectedRouter from '@root/quote.joinroot.com/src/components/protected-router';
import React, { useCallback, useEffect, useRef, useState } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import TrackingService from '@root/attribution/src/services/tracking-service';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import getCurrentUserContextConfiguration from '@root/user-session/src/api/get-current-user-context/get-current-user-context-configuration';
import getPolicyContextConfiguration from '@root/auto-pricing/src/api/get-policy-context/get-policy-context-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';
import { useHistory } from '@root/core/src/components/route';

const THIRTY_MINUTES_IN_MS = 1800000;
const currentUserContextConfiguration = getCurrentUserContextConfiguration();

export default function ProtectedApp({ inactiveTimeout = THIRTY_MINUTES_IN_MS, passProps }) {
  const [fetchPolicyContext] = useImperativeNetworkRequest(getPolicyContextConfiguration);
  const history = useHistory();
  const isMounted = useRef(false);
  const isLoading = useNetworkRequest(currentUserContextConfiguration, useCallback((result) => {
    const { currentUserContext } = result.data;
    TrackingService.addObject(currentUserContext);
    TrackingService.fireUserAuthenticatedEvent(currentUserContext.userId);
    AuthService.setCurrentUserContext(currentUserContext);
    datadogRum.setUser({
      id: result.data.currentUserContext.userId,
      accountId: result.data.currentUserContext.accountId,
      partner: AttributionService.formattedMediaSourceParameter(
        AttributionQueryParamService.read()
      ),
    });
    AnalyticsService.alias(currentUserContext.userId);
  }, []));
  const [policyContext, setPolicyContext] = useState({});

  useEffect(() => {
    AttributionService.associateVisitsToUser();
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  });

  const getPolicyContext = useCallback(async () => {
    const result = await fetchPolicyContext();

    if (isMounted.current) {
      if (result) {
        const builtPolicyContext = PolicyContext.buildFromData(result.data.policyContext);
        setPolicyContext(builtPolicyContext);
      }
    }
  }, [fetchPolicyContext]);

  useEffect(() => {
    const inactivityTimeout = window.setTimeout(() => history.push('/welcome-back'), inactiveTimeout);
    UserSessionService.updateSessionActivityToNow();

    return () => window.clearTimeout(inactivityTimeout);
  });

  if (isLoading) {
    return <SceneLoader />;
  }

  return (
    <PolicyContextProvider
      onReloadPolicyContext={getPolicyContext}
      policyContext={policyContext}
    >
      <ProtectedRouter passProps={passProps} />
    </PolicyContextProvider>
  );
}

ProtectedApp.propTypes = {
  inactiveTimeout: PropTypes.number,
  passProps: PropTypes.object.isRequired,
};
