import AuthService from '@root/core/src/services/auth-service';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import StorageService from '@root/core/src/services/storage-service';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import { useEffect } from '@root/vendor/react';
import { useHistory } from '@root/core/src/components/route';

const skipWelcomeBackRoutes = [
  '/password-reset',
  '/triage',
  '/landing/login',
  '/customer-surveys',
  '/quotes/selected',
  '/logout',
];

const shouldSkipWelcomeBack = (pathname) => {
  return skipWelcomeBackRoutes.some((route) => {
    return pathname.startsWith(route);
  });
};

export default function UserSessionController({ isEmbeddedInWebView = false, children }) {
  const history = useHistory();
  const shouldSkipIsRootRightForYou = StorageService.getItem('SKIP_IS_ROOT_RIGHT_FOR_YOU_STORAGE_KEY') ?? true;

  useEffect(() => {
    const accessToken = AuthService.getAccessToken();
    if (accessToken && !shouldSkipWelcomeBack(history.location.pathname) && !isEmbeddedInWebView && shouldSkipIsRootRightForYou) {
      if (UserSessionService.isActiveSession()) {
        history.push('/welcome-back' + history.location.search);
      } else {
        history.push('/welcome-back/login/email' + history.location.search);
      }
    }
  }, [history, shouldSkipIsRootRightForYou, isEmbeddedInWebView]);

  return (
    <>
      {children}
    </>
  );
}

UserSessionController.propTypes = {
  children: PropTypes.node.isRequired,
  isEmbeddedInWebView: PropTypes.bool,
};
