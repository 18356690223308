import PropTypes from '@root/vendor/prop-types';
import React, { useContext } from '@root/vendor/react';

const PolicyContext = React.createContext();

export default function PolicyContextProvider({
  children, policyContext, onReloadPolicyContext,
}) {
  return (
    <PolicyContext.Provider
      value={{
        policyContext,
        reloadPolicyContext: onReloadPolicyContext,
      }}
    >
      {children}
    </PolicyContext.Provider>
  );
}

PolicyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onReloadPolicyContext: PropTypes.func.isRequired,
  policyContext: PropTypes.object.isRequired,
};

export function usePolicyContext() {
  const { policyContext, reloadPolicyContext } = useContext(PolicyContext);

  return {
    policyContext,
    reloadPolicyContext,
  };
}
