import PropTypes from '@root/vendor/prop-types';
import TrackingService from '@root/attribution/src/services/tracking-service';
import useStoreAttribution from '@root/attribution/src/hooks/use-save-attribution';
import { useEffect } from '@root/vendor/react';
import { withRouter } from '@root/core/src/components/route';

export function AttributionController({ history, location }) {
  useStoreAttribution(location);

  useEffect(() => {
    history.listen(() => {
      // eslint-disable-next-line root/prevent-use-of-window-location
      TrackingService.fireHistoryChangeEvent(window.location.href);
    });
  }, [history]);

  return null;
}

AttributionController.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(AttributionController);
