import Profile from '@root/auto-pricing/src/models/profile';

export default class Policy {
  constructor({
    id, number, profile, quoteId, policyDocumentUrl, declarationsPageUrl, insuranceCardDocumentUrl, renewalDocumentUrl,
  } = {}) {
    this.id = id;
    this.number = number;
    this.profile = profile;
    this.quoteId = quoteId;
    this.policyDocumentUrl = policyDocumentUrl;
    this.declarationsPageUrl = declarationsPageUrl;
    this.insuranceCardDocumentUrl = insuranceCardDocumentUrl;
    this.renewalDocumentUrl = renewalDocumentUrl;
  }

  static buildFromData({
    id, number, profile = {}, quoteId, policyDocumentUrl, declarationsPageUrl, insuranceCardDocumentUrl, renewalDocumentUrl,
  } = {}) {
    return Object.assign(
      new Policy(),
      {
        id,
        number,
        profile: Profile.buildFromData(profile),
        quoteId,
        policyDocumentUrl,
        declarationsPageUrl,
        insuranceCardDocumentUrl,
        renewalDocumentUrl,
      },
    );
  }
}
