import AppsflyerQueryParamService from '@root/attribution/src/services/appsflyer-query-param-service';
import environment from '@root/core/src/utils/environment';

export default class AppsflyerService {
  static storeAppsflyerReferralHref(sourcePathUrl) {
    AppsflyerQueryParamService.store(sourcePathUrl);
  }

  static getAppsflyerLinkWithParameters() {
    const params = AppsflyerQueryParamService.read();

    const appsflyerParams = {
      pid: 'web_app',
      ...params,
    };
    const urlParams = new URLSearchParams();

    Object.entries(appsflyerParams).forEach(([key, value]) => urlParams.append(key, value));

    return AppsflyerService._joinQuery(environment.appsFlyerOneLinkUrl, urlParams.toString());
  }

  static _joinQuery(path, query) {
    if (query) {
      return `${path}?${query}`;
    }

    return path;
  }
}
