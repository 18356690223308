import Policy from '@root/auto-pricing/src/models/policy';

export default class PolicyContext {
  constructor({
    policy,
    effectiveDate,
    expirationDate,
    repaymentStrategy,
    canceled,
    futureCancelDate,
    invoicePeriod,
    isExpiredPendingRenewal,
    isExpired,
    hasExpiredStatus,
    activeQuote,
    canReinstate,
    alternativeQuoteWebLink,
    policyAutomatedNonRenewed,
    policyAutomatedNonRenewedReason,
    deadlineToAcceptRenewalOffer,
  } = {}) {
    this.policy = policy;
    this.repaymentStrategy = repaymentStrategy;
    this.canceled = canceled;
    this.futureCancelDate = futureCancelDate;
    this.effectiveDate = effectiveDate;
    this.expirationDate = expirationDate;
    this.isExpiredPendingRenewal = isExpiredPendingRenewal;
    this.isExpired = isExpired;
    this.hasExpiredStatus = hasExpiredStatus;
    this.invoicePeriod = invoicePeriod;
    this.activeQuote = activeQuote;
    this.canReinstate = canReinstate;
    this.alternativeQuoteWebLink = alternativeQuoteWebLink;
    this.policyAutomatedNonRenewed = policyAutomatedNonRenewed;
    this.policyAutomatedNonRenewedReason = policyAutomatedNonRenewedReason;
    this.deadlineToAcceptRenewalOffer = deadlineToAcceptRenewalOffer;
  }

  static buildFromData({
    policy,
    effectiveDate,
    expirationDate,
    repaymentStrategy,
    canceled,
    futureCancelDate,
    isExpiredPendingRenewal,
    isExpired,
    hasExpiredStatus,
    invoicePeriod,
    activeQuote,
    canReinstate,
    alternativeQuoteWebLink,
    policyAutomatedNonRenewed,
    policyAutomatedNonRenewedReason,
    deadlineToAcceptRenewalOffer,
  } = {}) {
    return Object.assign(
      new PolicyContext(),
      {
        policy: Policy.buildFromData(policy),
        repaymentStrategy,
        canceled,
        futureCancelDate,
        effectiveDate,
        expirationDate,
        isExpiredPendingRenewal,
        isExpired,
        hasExpiredStatus,
        invoicePeriod,
        activeQuote,
        canReinstate,
        alternativeQuoteWebLink,
        policyAutomatedNonRenewed,
        policyAutomatedNonRenewedReason,
        deadlineToAcceptRenewalOffer,
      },
    );
  }

  isConditionalRepayment() {
    return this.repaymentStrategy === 'conditional';
  }
}
