import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import { FunnelStages } from '@root/core/src/models/funnel-stages';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

const CustomerSurveysEntry = lazy(() => import(/* webpackChunkName: "customer-surveys" */ '@root/customer-surveys/src/customer-surveys-entry'));
const LandingEntry = lazy(() => import(/* webpackChunkName: "landing" */ '@root/landing/src/landing-entry'));
const IsRootRightForYouEntry = lazy(() => import(/* webpackChunkName: "is-root-right-for-you" */ '@root/is-root-right-for-you/src/is-root-right-for-you-entry'));
const SplashEntry = lazy(() => import(/* webpackChunkName: "splash" */ '@root/splash/src/splash-entry'));
const PasswordResetEntry = lazy(() => import(/* webpackChunkName: "landing" */ '@root/password-reset/src/password-reset-entry'));
const ContactSupportEntry = lazy(() => import(/* webpackChunkName: "contact-support" */ '@root/contact-support/src/contact-support-entry'));
import(/* webpackChunkName: "poller-animation", webpackPrefetch: true */ '@root/core/src/assets/animations/road');

export default function PublicRouter({ passProps }) {
  return (
    <Switch>
      <Route
        funnelStage={FunnelStages.LANDING}
        path={'/landing'}
        render={() => (
          <LandingEntry
            passProps={passProps}
          />
        )}
      />
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/is-root-right-for-you'}
        render={() => (
          <IsRootRightForYouEntry
            passProps={passProps}
          />
        )}
      />
      <Route
        funnelStage={FunnelStages.SPLASH}
        path={'/splash'}
        render={() => (
          <SplashEntry
            passProps={passProps}
          />
        )}
      />
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/password-reset'}
        render={() => (
          <PasswordResetEntry
            passProps={passProps}
          />
        )}
      />
      <Route
        path={'/customer-surveys'}
        render={() => (
          <CustomerSurveysEntry
            passProps={passProps}
          />
        )}
      />
      <Route
        path={'/contact-support'}
        render={() => (
          <ContactSupportEntry
            passProps={passProps}
          />
        )}
      />
      <Redirect to={'/landing'} />
    </Switch>
  );
}

PublicRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
