import sum from '@root/vendor/lodash/sum';

const WHITELIST = {
  JEEP_RENEGADE_VIN: /ZACCJBBT[A-Z0-9]FP[A-Z0-9]{6}/,
  FIAT_500_VIN: /ZFBCF[XY][BC]T0GP3\d{5}/,
};

const KNOWN_VALID_VINS = {
  CHEVROLET_CORVETTE_1980: '1Z878AS401177',
  LINCOLN_MKX_PREMIERE_2016: '2LMTJ8JR1GB316466',
};

const TRANSLITERATIONS = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  J: 1,
  K: 2,
  L: 3,
  M: 4,
  N: 5,
  P: 7,
  R: 9,
  S: 2,
  T: 3,
  U: 4,
  V: 5,
  W: 6,
  X: 7,
  Y: 8,
  Z: 9,
};
const WEIGHT_FACTORS = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
const VALID = {
  isValid: true,
};

function calculateCheckDigit(vin) {
  const chars = vin.toUpperCase().split('');
  const factors = chars.map((char, index) => {
    return TRANSLITERATIONS[char] * WEIGHT_FACTORS[index];
  });
  const result = sum(factors) % 11;

  if (result === 10) {
    return 'X';
  } else {
    return String(result);
  }
}

function verifyCheckDigit(vin) {
  const chars = vin.toUpperCase().split('');

  return chars[8] === calculateCheckDigit(vin);
}

function isWhitelistedVin(vin) {
  const vins = Object.values(WHITELIST);

  return vins.some((v) => v.test(vin));
}

function isKnownValidVin(vin) {
  const vins = Object.values(KNOWN_VALID_VINS);

  return vins.some((knownValidVin) => knownValidVin === vin);
}

function isValidModelYear(vin) {
  const tenthDigit = vin[9];

  return tenthDigit !== '0';
}

export default class Vin {
  static LENGTH = 17;

  static validate(vin, vehicles = []) {
    const buildError = (err) => ({
      isValid: false,
      error: err,
    });

    if (!vin) {
      return buildError('Please enter a VIN');
    }

    if (vehicles.some((v) => v.vin === vin && v.selected)) {
      return buildError('You’ve already added that vehicle.');
    }

    if (isKnownValidVin(vin)) {
      return VALID;
    }

    if (vin.length !== Vin.LENGTH) {
      const delta = Vin.LENGTH - vin.length;
      if (delta > 0) {
        return buildError(`Please enter ${delta} more character` + (delta === 1 ? '' : 's'));
      } else {
        return buildError(`That’s ${Math.abs(delta)} too many characters`);
      }
    }

    if (isWhitelistedVin(vin)) {
      return VALID;
    }

    if (/[IOQ]/i.test(vin)) {
      return buildError('Should not contain I, O, or Q');
    }

    if (!verifyCheckDigit(vin)) {
      return buildError('Make sure you enter a valid VIN.');
    }

    if (!isValidModelYear(vin)) {
      return buildError('Sorry, that’s not a valid VIN');
    }

    return VALID;
  }

  static calculateCheckDigit(vin) {
    const chars = vin.toUpperCase().split('');
    const factors = chars.map((char, index) => {
      return TRANSLITERATIONS[char] * WEIGHT_FACTORS[index];
    });
    const result = sum(factors) % 11;

    if (result === 10) {
      return 'X';
    } else {
      return String(result);
    }
  }
}
