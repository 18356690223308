import AbTestProvider from '@root/core/src/contexts/ab-test/ab-test-provider';
import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AnalyticsService from '@root/core/src/services/analytics-service';
import AttributionController from '@root/quote.joinroot.com/src/components/attribution-controller';
import AuthController from '@root/auth/src/components/auth-controller';
import AuthService from '@root/core/src/services/auth-service';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import ExperienceProvider from '@root/core/src/contexts/experience/experience-provider';
import FormProvider from '@root/core/src/contexts/form/form-provider';
import PropTypes from '@root/vendor/prop-types';
import ProtectedApp from '@root/quote.joinroot.com/src/components/protected-app';
import PublicRouter from '@root/quote.joinroot.com/src/components/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import TrackVisitAttributionController from '@root/attribution/src/components/track-visit-attribution-controller';
import TrackingService from '@root/attribution/src/services/tracking-service';
import UserSessionController from '@root/user-session/src/components/user-session-controller';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';
import { RootIdService } from '@root/attribution/src/services/root-id-service';
import { Suspense, useEffect, useState } from '@root/vendor/react';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';

if (environment.dataDogEnabled) {
  datadogRum.init({
    applicationId: environment.dataDogApplicationId,
    clientToken: environment.dataDogClientToken,
    site: 'datadoghq.com',
    service: 'quote.joinroot.com',
    env: environment.environment,
    version: environment.releaseSha,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https?:\/\/localhost(:\d+)?/, /https?:\/\/(.+\.)?joinroot.com(:\d+)?/],
    trackSessionAcrossSubdomains: true,
  });

  // eslint-disable-next-line root/prevent-use-of-window-location
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  datadogRum.addRumGlobalContext('urlParameters', urlParams);

  const rootId = RootIdService.read();
  datadogRum.addRumGlobalContext('rootId', rootId);

  datadogRum.startSessionReplayRecording();
}

function App({ routerComponent: Router, history }) {
  const [tokenSet, setTokenSet] = useState(false);

  useAnalytics('APP_ENTRY');
  useSaveTouchAttributionEffect();

  useEffect(() => {
    TrackingService.fireDistinctIdSetEvent(AnalyticsService.getDistinctId());
  }, []);

  window.setMobileAccessToken = (token) => {
    if (!tokenSet) {
      UserSessionService.initializeSessionActivity();
      AuthService.setAccessToken(token);
      setTokenSet(true);
    }
  };

  return (
    <Router history={history}>
      <FormProvider>
        <ScrollToTop>
          <Suspense fallback={<SceneLoader />}>
            <TrackVisitAttributionController />
            <AttributionController />
            <UserSessionController isEmbeddedInWebView={!!window.ReactNativeWebView}>
              <AuthController
                passProps={{
                  moduleName: 'quote',
                  isEmbeddedInWebview: !!window.ReactNativeWebView,
                }}
                ProtectedApp={ProtectedApp}
                PublicRouter={PublicRouter}
                SceneLoader={SceneLoader}
              />
            </UserSessionController>
          </Suspense>
        </ScrollToTop>
      </FormProvider>
    </Router>
  );
}

App.propTypes = {
  history: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({
  initialAbTests = {}, initialExperiences = {}, routerComponent, history,
}) {
  return (
    <ErrorBoundary>
      <AbTestProvider initialAbTests={initialAbTests}>
        <ExperienceProvider initialExperiences={initialExperiences}>
          <AnalyticsProvider eventPrefix={'WEB_APP'}>
            <App
              history={history}
              routerComponent={routerComponent}
            />
          </AnalyticsProvider>
        </ExperienceProvider>
      </AbTestProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  history: PropTypes.object,
  initialAbTests: PropTypes.object,
  initialExperiences: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};
