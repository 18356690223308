import Address from '@root/core/src/models/address';
import Driver from '@root/auto-pricing/src/models/driver';
import Vehicle from '@root/core/src/models/vehicle';

export default class Profile {
  constructor({
    drivers, vehicles, address, driverVehicleAssignments,
  } = {}) {
    this.drivers = drivers;
    this.vehicles = vehicles;
    this.address = address;
    this.driverVehicleAssignments = driverVehicleAssignments;
  }

  static buildFromData({
    drivers = [],
    vehicles = [],
    address1,
    address2,
    city,
    state,
    zip,
    driverVehicleAssignments,
    testDriveSelected,
    primaryNamedInsured,
    homeowner,
    ratingMunicipality,
    market,
    rideshare,
    selfReportedMovingViolations,
  } = {}) {
    drivers = drivers.map((driver) => {
      return driver instanceof Driver ? driver : Driver.buildFromData(driver);
    });
    vehicles = vehicles.map((vehicle) => {
      return vehicle instanceof Vehicle ? vehicle : Vehicle.buildFromData(vehicle);
    });

    return Object.assign(
      new Profile(),
      {
        drivers,
        vehicles,
        address: Address.buildFromData({
          line1: address1,
          line2: address2,
          city,
          state,
          zip,
        }),
        driverVehicleAssignments,
        testDriveSelected,
        primaryNamedInsured,
        homeowner,
        ratingMunicipality,
        market,
        rideshare,
        selfReportedMovingViolations,
      },
    );
  }
}
