import Market from '@root/core/src/models/market';
import compact from '@root/vendor/lodash/compact';
import isEmpty from '@root/vendor/lodash/isEmpty';
import { ADDRESS_LINE_ONE_REGEX, SHORT_ZIP_REGEX, isFormatted, isRequired } from '@root/core/src/utils/validators';

export default class Address {
  static validations = [
    isRequired('line1', 'Address line 1 is required'),
    isFormatted('line1', ADDRESS_LINE_ONE_REGEX, 'Address is invalid. Enter a number, followed by a street name.'),
    isRequired('city', 'Enter the city name.'),
    isRequired('state', 'State is required'),
    isRequired('zip', 'ZIP is required'),
    isFormatted('zip', SHORT_ZIP_REGEX, 'Enter a 5-digit ZIP.'),
  ];

  static buildFromData({
    line1, line2, city, state, zip, latitude, longitude,
  } = {}) {
    return Object.assign(
      new Address(),
      {
        line1,
        line2,
        city,
        state,
        zip,
        latitude,
        longitude,
      }
    );
  }

  constructor() {
    this.line1 = null;
    this.line2 = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.country = null;
    this.latitude = null;
    this.longitude = null;
  }

  addressText() {
    if (this.isValid) {
      return compact([
        this.line1,
        this.line2,
        this.city,
        this.state,
        this.zip,
        this.country,
      ]).join(', ');
    } else {
      return '';
    }
  }

  streetAddress() {
    if (this.isValid) {
      return compact([
        this.line1,
        this.line2,
      ]).join(', ');
    } else {
      return '';
    }
  }

  cityStateZip() {
    if (this.isValid) {
      return compact([
        compact([
          this.city,
          this.state,
        ]).join(', '),
        this.zip,
      ]).join(' ');
    } else {
      return '';
    }
  }

  stateTitle() {
    return Market.MAPPING[this.state] || '';
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new Address(),
      this,
      attributes,
    );
  }

  isGeocoded() {
    return !!(this.latitude && this.longitude);
  }

  get isValid() {
    return isEmpty(this.errors());
  }

  errors() {
    const errs = {};
    Address.validations.forEach((validation) => validation(this, errs));
    return errs;
  }

  errorsFor(fieldName) {
    return this.errors()[fieldName];
  }

  serializeForSubmission() {
    return {
      address1: this.line1,
      address2: this.line2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }

  serializeWithoutLatLng() {
    const address = this.serializeForSubmission();
    delete address.latitude;
    delete address.longitude;
    return address;
  }

  static buildFromGooglePlace = (googlePlace, address2) => {
    const googleAddressComponents = googlePlace.components;
    const streetAddress = googlePlace?.line1?.replace(googleAddressComponents?.route?.long_name, googleAddressComponents?.route?.short_name);
    return Address.buildFromData({
      line1: streetAddress || googlePlace.line1,
      line2: address2 || '',
      city: googlePlace.city,
      state: googlePlace.stateCode,
      zip: googlePlace.zip,
    });
  };

  static buildComponentsFromGooglePrediction(prediction) {
    const streetNum = prediction.components.street_number?.short_name;
    const street = prediction.components.route?.short_name;
    const line1 = `${streetNum}${streetNum ? ' ' : ''}${street}`;
    const city = prediction.components.locality?.long_name;
    const state = prediction.components.administrative_area_level_1?.short_name;
    const zip = prediction.components.postal_code?.long_name;

    return {
      line1,
      city,
      state,
      zip,
    };
  }
}
